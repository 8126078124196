// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const sidebarAdminMenu = [
  {
    title: 'dashboard',
    path: '/admin',
    icon: <HomeOutlined color='inherit' />,
  },
  {
    title: 'User management',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'User',
        path: '/admin/user',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Contractor',
        path: '/admin/contractor',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Vendor',
        path: '/admin/vendor',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      // {
      //   title: 'Guest',
      //   path: '/admin/guest',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // },
    ]
  },
  {
    title: 'Kitchen',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Menu Master',
        path: '/admin/menuMaster',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Menu Item Master',
        path: '/admin/menuItemMaster',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Contract Book',
        path: '/admin/contractBook',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      // {
      //   title: 'Guest Book',
      //   path: '/admin/guestBook',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // }
    ]
  },
  {
    title: 'Portal design',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Roster Management',
        path: '/admin/rosterManagement',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Login Carousel',
        path: '/admin/loginCarousel',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      // {
      //   title: 'Food Wastage',
      //   path: '/admin/foodWastage',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // },
      {
        title: 'Freeze Booking',
        path: '/admin/freezeBooking',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Push Notification',
        path: '/admin/notification',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Schedule Notification',
        path: '/admin/scheduleNotification',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  }
]

// for super admin

export const sidebarReportMenu = [
  {
    title: 'User',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'User History',
        path: '/admin/userHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order Tracking',
        path: '/admin/orderTracking',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Snacks Report',
        path: '/admin/getSnacksReport',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      // {
      //   title: 'Delivery History',
      //   path: '/admin/empDeliveryHistory',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // },
      // {
      //   title: 'Special Tracking',
      //   path: '/admin/specialTracking',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // },
    ]
  },
  {
    title: 'Contractor',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Order History',
        path: '/admin/orderHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Delivery History',
        path: '/admin/deliveryHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Swipe History',
        path: '/admin/contractorSwipeHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Invoice',
        path: '/admin/contractorInvoice',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  {
    title: 'Guest',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Guest History',
        path: '/admin/normalGuest',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order Tracking',
        path: '/admin/guestOrderTracking',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  // {
  //   title: 'Special Guest',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'Special Guest',
  //       path: '/admin/specialGuest',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Order Tracking',
  //       path: '/admin/specialGuestOrderTracking',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     }
  //   ]
  // },
  // {
  //   title: 'Kiosk',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'User History',
  //       path: '/admin/kioskUserHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Order Tracking',
  //       path: '/admin/kioskOrderTracking',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //   ]
  // },
  // {
  //   title: 'Food Admin',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'User History',
  //       path: '/admin/kioskUserHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Order Tracking',
  //       path: '/admin/kioskOrderTracking',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //   ]
  // },
  // {
  //   title: 'Contractor Admin',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'Booking History',
  //       path: '/admin/bookingHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Delivery History',
  //       path: '/admin/deliveryHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Swipe History',
  //       path: '/admin/swipeHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //   ]
  // },
  // {
  //   title: 'Food Clerk',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'Order History',
  //       path: '/admin/orderHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //   ]
  // },
  // {
  //   title: 'Guest Admin',
  //   icon: <HomeOutlined color='inherit' />,
  //   children: [
  //     {
  //       title: 'Booking History',
  //       path: '/admin/guestAdmin/bookingHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Delivery History',
  //       path: '/admin/guestAdmin/deliveryHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //     {
  //       title: 'Swipe History',
  //       path: '/admin/guestAdmin/swipeHistory',
  //       icon: <ShoppingCartOutlined color='inherit' />
  //     },
  //   ]
  // },
  {
    title: 'Other reports',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Sale Report',
        path: '/admin/saleReport',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Day By Day Order',
        path: '/admin/adminDayByDayOrder',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Emp/Contractor/Special',
        path: '/admin/user_contractor',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Feedbacks',
        path: '/admin/feedbacks',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Ratings',
        path: '/admin/rating',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      // {
      //   title: 'Activity',
      //   path: '/admin/activity',
      //   icon: <ShoppingCartOutlined color='inherit' />
      // },
    ]
  },
];

// for admin

export const sidebarAdminReportMenu = [
  {
    title: 'User',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'User History',
        path: '/admin/userHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order Tracking',
        path: '/admin/orderTracking',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Snacks Report',
        path: '/admin/getSnacksReport',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  },
  {
    title: 'Contractor',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Order History',
        path: '/admin/orderHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Delivery History',
        path: '/admin/deliveryHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Swipe History',
        path: '/admin/contractorSwipeHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Invoice',
        path: '/admin/contractorInvoice',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  {
    title: 'Special Guest',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Special Guest',
        path: '/admin/specialGuest',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  {
    title: 'Other reports',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Sale Report',
        path: '/admin/saleReport',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Day By Day Order',
        path: '/admin/adminDayByDayOrder',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Emp/Contractor/Special',
        path: '/admin/user_contractor',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Feedbacks',
        path: '/admin/feedbacks',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Ratings',
        path: '/admin/rating',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
];