import React, { useMemo } from 'react';
import EmpChildTableHead from './empChildHead';
import EditTextField from '../../../overrides/editField';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Autocomplete
} from '@mui/material';
import { useSelector } from 'react-redux'



const EmployeeChildTable = ({ expandOpen, rowData, order, orderBy, handleRequestSort, handleEditChange, status, editData, setEditData, passErr, formErrors, setValid }) => {

    const { EmployeeReducers: { accessPointList, divisionList, departmentNameList, categoryList, counter: counterList } } = useSelector((state) => state)

    const TABLE_HEAD = useMemo(() => ([
        { id: 'cUserName', label: 'User Name', alignRight: false },
        { id: 'nAccessPoint', label: 'Access Points', alignRight: false },
        { id: 'nDivisionID', label: 'Division', alignRight: false },
        { id: 'cPassword', label: 'Password', alignRight: false },
        ...(
            rowData.cRoleName === 'CounterAdmin' ?
                [{ id: 'counterName', label: 'Counter Name', alignRight: false }]
                :
                [{ id: 'cRFID', label: 'RFID', alignRight: false },
                { id: 'categoryName', label: 'Category Name', alignRight: false },
                { id: 'departmentName', label: 'Department Name', alignRight: false },]
        ),
        { id: 'DaysToExpiry', label: 'Days To Expiry', alignRight: false },
    ]), [rowData.cRoleName])

    let division = divisionList.find(m => m.id === rowData.nDivisionID) || null
    let category = categoryList.find(m => m.name === rowData.categoryName) || null
    let department = departmentNameList.find(m => m.name === rowData.departmentName) || null
    let counter = counterList.find(m => m.id === rowData.nCounterID) || null
    let getAccesspointNames = rowData.apNames?.split(',') || []
    let accessPoints = accessPointList.filter(m => getAccesspointNames.includes(m.name)) || []

    console.log('rowData',editData.nAccessPoint)

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table>
                        <EmpChildTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cUserName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.apNames}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cDivisionName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cPassword}
                                            </Typography>
                                        </TableCell>
                                        {
                                            rowData.cRoleName === 'CounterAdmin' ?
                                                <TableCell align="left">
                                                    <Typography variant='t14_600'>
                                                        {rowData.counterName || `---`}
                                                    </Typography>
                                                </TableCell>
                                                :
                                                <>
                                                    <TableCell align="left">
                                                        <Typography variant='t14_600'>
                                                            {rowData.cRFID || `---`}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant='t14_600'>
                                                            {rowData.categoryName || `---`}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant='t14_600'>
                                                            {rowData.departmentName || `---`}
                                                        </Typography>
                                                    </TableCell>
                                                </>
                                        }
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.DaysToExpiry}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    :
                                        rowData.cRoleName === 'FoodAdmin' || rowData.cRoleName === 'ContractorAdmin' || rowData.cRoleName === 'GuestAdmin' ?
                                            <>
                                                <TableCell align="left">
                                                    <Typography variant='t14_600'>
                                                        {rowData.cUserName}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nAccessPoint"
                                                        multiple
                                                        fullWidth
                                                        options={accessPointList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={accessPoints}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            let Val = value.map(m => m.id)
                                                            console.log('asdwef',Val)
                                                            // if(Val.length > 0 && Val[0] !== 0){
                                                            //     setEditData({ ...editData, nAccessPoint: `${Val}` });
                                                            // }
                                                            if(Val.length > 0 && Val[0] !== 0){
                                                                setValid(false)
                                                            }
                                                            else{
                                                                setValid(true)
                                                            }
                                                            setEditData({ ...editData, nAccessPoint: `${Val}` });
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                                                            name='nAccessPoint'
                                                            error={editData.nAccessPoint ? false : true}
                                                            helperText={editData.nAccessPoint ? '' : 'AccessPoints is required'}
                                                        />}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    {/* <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nDivisionID"
                                                        fullWidth
                                                        options={divisionList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={division}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            if(value.id !== 0){
                                                                setEditData({ ...editData, nDivisionID: `${value.id}` });
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px' }}
                                                            name='nDivisionID'
                                                        />}
                                                    /> */}
                                                    <Typography variant='t14_600'>
                                                        {rowData.cDivisionName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <EditTextField
                                                        name='cPassword'
                                                        value={editData.cPassword}
                                                        onChange={handleEditChange}
                                                        onBlur={handleEditChange}
                                                        error={passErr ? true : false}
                                                        helperText={passErr ? passErr : ''}
                                                        sx={{ minWidth: '160px' }}
                                                    />
                                                </TableCell>
                                                {(rowData.cRoleName === 'Employee') || (rowData.cRoleName === 'FoodClerk') || (rowData.cRoleName === 'SpecialGuest') ?
                                                    <>
                                                        <TableCell align="left">
                                                            <EditTextField
                                                                name='cRFID'
                                                                value={editData.cRFID}
                                                                onChange={handleEditChange}
                                                                error={editData.cRFID ? false : formErrors.cRFID !== null ? true : false}
                                                                helperText={editData.cRFID ? '' : formErrors.cRFID !== null ? 'RFID Number is required' : ''}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nCategory"
                                                                fullWidth
                                                                options={categoryList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={category}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    console.log('sdfkwk',value)
                                                                    if(value.id !== 0){
                                                                        setValid(false)
                                                                    }
                                                                    else{
                                                                        setValid(true)
                                                                    }
                                                                    // if(value.id !== 0){
                                                                        setEditData({ ...editData, nCategory: `${value.id}` });
                                                                    // }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nCategory'
                                                                    error={editData.nCategory > 0 ? false : true}
                                                                    helperText={editData.nCategory > 0 ? '' : 'Category is required'}
                                                                />}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nDepartment"
                                                                fullWidth
                                                                options={departmentNameList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={department}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    if(value.id !== 0){
                                                                        setValid(false)
                                                                    }
                                                                    else{
                                                                        setValid(true)
                                                                    }
                                                                    // if(value.id !== 0){
                                                                        setEditData({ ...editData, nDepartment: `${value.id}` });
                                                                    // }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nDepartment'
                                                                    error={editData.nDepartment > 0 ? false : true}
                                                                    helperText={editData.nDepartment > 0 ? '' : 'Department is required'}
                                                                />}
                                                            />
                                                        </TableCell>
                                                    </>
                                                    :
                                                    rowData.cRoleName === 'CounterAdmin' ?
                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nCounterID"
                                                                fullWidth
                                                                options={counterList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={counter}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    // console.log('sdcefg',value)
                                                                    if(value.id !== 0){
                                                                        setEditData({ ...editData, nCounterID: `${value.id}` });
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nCounterID'
                                                                    error={editData.nCounterID ? false : formErrors.nCounterID !== null ? true : false}
                                                                    helperText={editData.nCounterID ? '' : formErrors.nCounterID !== null ? 'Counter Name is required' : ''}
                                                                />}
                                                            />
                                                        </TableCell>
                                                        :
                                                        <>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.cRFID || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.categoryName || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.departmentName || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                        </>
                                                }
                                                <TableCell align="left">
                                                    <EditTextField
                                                        name='DaysToExpiry'
                                                        value={editData.DaysToExpiry}
                                                        onChange={handleEditChange}
                                                        onBlur={handleEditChange}
                                                        sx={{ minWidth: '160px' }}
                                                        error={editData.DaysToExpiry ? false : formErrors.DaysToExpiry !== null ? true : false}
                                                        helperText={editData.DaysToExpiry ? '' : formErrors.DaysToExpiry !== null ? 'DaysToExpiry is required' : ''}
                                                    />
                                                </TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell align="left">
                                                    <Typography variant='t14_600'>
                                                        {rowData.cUserName}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nAccessPoint"
                                                        multiple
                                                        fullWidth
                                                        options={accessPointList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={accessPoints}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            let Val = value.map(m => m.id)
                                                            console.log('asdwef',Val, value)
                                                            if(Val.length > 0 && Val[0] !== 0){
                                                                setValid(false)
                                                            }
                                                            else{
                                                                setValid(true)
                                                            }
                                                            if(Val[0] !== 0){
                                                                setEditData({ ...editData, nAccessPoint: `${Val}` });
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px', '& .MuiOutlinedInput-root': { height: 'auto' } }}
                                                            name='nAccessPoint'
                                                            error={editData.nAccessPoint ? false : true}
                                                            helperText={editData.nAccessPoint ? '' : 'AccessPoints is required'}
                                                        />}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        name="nDivisionID"
                                                        fullWidth
                                                        options={divisionList}
                                                        size="small"
                                                        autoHighlight
                                                        defaultValue={division}
                                                        // value={roleName.name}
                                                        onChange={(event, value) => {
                                                            if(value.id !== 0){
                                                                setEditData({ ...editData, nDivisionID: `${value.id}` });
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <EditTextField {...params}
                                                            sx={{ minWidth: '160px' }}
                                                            name='nDivisionID'
                                                        />}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <EditTextField
                                                        name='cPassword'
                                                        value={editData.cPassword}
                                                        onChange={handleEditChange}
                                                        onBlur={handleEditChange}
                                                        error={passErr ? true : false}
                                                        helperText={passErr ? passErr : ''}
                                                        sx={{ minWidth: '160px' }}
                                                    />
                                                </TableCell>
                                                {(rowData.cRoleName === 'Employee') || (rowData.cRoleName === 'FoodClerk') || (rowData.cRoleName === 'SpecialGuest') ?
                                                    <>
                                                        <TableCell align="left">
                                                            <EditTextField
                                                                name='cRFID'
                                                                value={editData.cRFID}
                                                                onChange={handleEditChange}
                                                                error={editData.cRFID ? false : formErrors.cRFID !== null ? true : false}
                                                                helperText={editData.cRFID ? '' : formErrors.cRFID !== null ? 'RFID Number is required' : ''}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nCategory"
                                                                fullWidth
                                                                options={categoryList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={category}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    if(value.id !== 0){
                                                                        setValid(false)
                                                                    }
                                                                    else{
                                                                        setValid(true)
                                                                    }
                                                                    setEditData({ ...editData, nCategory: `${value.id}` });
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nCategory'
                                                                    error={editData.nCategory > 0 ? false : true}
                                                                    helperText={editData.nCategory > 0 ? '' : 'Category is required'}
                                                                />}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nDepartment"
                                                                fullWidth
                                                                options={departmentNameList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={department}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    if(value.id !== 0){
                                                                        setValid(false)
                                                                    }
                                                                    else{
                                                                        setValid(true)
                                                                    }
                                                                    // if(value.id !== 0){
                                                                        setEditData({ ...editData, nDepartment: `${value.id}` });
                                                                    // }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nDepartment'
                                                                    error={editData.nDepartment > 0 ? false : true}
                                                                    helperText={editData.nDepartment > 0 ? '' : 'Department is required'}
                                                                />}
                                                            />
                                                        </TableCell>
                                                    </>
                                                    :
                                                    rowData.cRoleName === 'CounterAdmin' ?
                                                        <TableCell align="left">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                name="nCounterID"
                                                                fullWidth
                                                                options={counterList}
                                                                size="small"
                                                                autoHighlight
                                                                defaultValue={counter}
                                                                // value={roleName.name}
                                                                onChange={(event, value) => {
                                                                    // console.log('sdcefg',value)
                                                                    if(value.id !== 0){
                                                                        setEditData({ ...editData, nCounterID: `${value.id}` });
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <EditTextField {...params}
                                                                    sx={{ minWidth: '160px' }}
                                                                    name='nCounterID'
                                                                    error={editData.nCounterID ? false : formErrors.nCounterID !== null ? true : false}
                                                                    helperText={editData.nCounterID ? '' : formErrors.nCounterID !== null ? 'Counter Name is required' : ''}
                                                                />}
                                                            />
                                                        </TableCell>
                                                        :
                                                        <>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.cRFID || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.categoryName || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='t14_600'>
                                                                    {rowData.departmentName || `---`}
                                                                </Typography>
                                                            </TableCell>
                                                        </>
                                                }
                                                <TableCell align="left">
                                                    <EditTextField
                                                        name='DaysToExpiry'
                                                        value={editData.DaysToExpiry}
                                                        onChange={handleEditChange}
                                                        onBlur={handleEditChange}
                                                        sx={{ minWidth: '160px' }}
                                                        error={editData.DaysToExpiry ? false : formErrors.DaysToExpiry !== null ? true : false}
                                                        helperText={editData.DaysToExpiry ? '' : formErrors.DaysToExpiry !== null ? 'DaysToExpiry is required' : ''}
                                                    />
                                                </TableCell>
                                            </>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default EmployeeChildTable;